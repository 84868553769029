<template>
  <div class="pos-abono-prestamo">
    <div class="pos-abono text-center mt-2" v-if="abono.abono != undefined">
      <div class="header">
        <h2>
          {{ abono.header.razon }}
        </h2>
        <p>
          Nit: {{ abono.header.nit }}
          <br />
          Dirección: {{ abono.header.direccion }}
          <br />
          Teléfono: {{ abono.header.telefono }}
        </p>
      </div>
      <div class="body">
        <table>
          <tbody>
            <tr>
              <th>
                Profesional
              </th>
              <td>
                {{ abono.gasto.profesional }}
              </td>
            </tr>
            <tr>
              <th>
                Nit / Cc
              </th>
              <td>
                {{ abono.gasto.cedula }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th></th>
              <th></th>
            </tr>
          </tfoot>
        </table>
        <table>
          <thead>
            <tr>
              <th>
                Prestamo
              </th>
              <th style="text-align:right !important">
                {{ abono.gasto.gasto }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Observación</th>
              <td>
                {{ abono.gasto.observacion }}
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                Saldo anterior
              </th>
              <td>
                {{
                  parseFloat(abono.abono.saldo)
                    | currency
                }}
              </td>
            </tr>
            <tr>
              <th>
                Valor Abono
              </th>
              <td>
                {{ abono.abono.valor | currency }}
              </td>
            </tr>
            <tr>
              <th>
                Saldo Actual
              </th>
              <td>
                {{ abono.abono.saldo | currency }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th></th>
              <th></th>
            </tr>
          </tfoot>
        </table>
      </div>
      <strong>
        <small>www.luxcultury.com</small>
      </strong>
    </div>
    <v-row>
      <v-col>
        <v-btn dark color="black" class="ml-2 d-print-none" @click="print()"
          >Imprimir</v-btn
        >
        <v-btn color="primary" class="ml-2 d-print-none" @click="close()"
          >Cerrar</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "posAbonoPrestamo",
  data: () => ({
    abono: {},
  }),
  methods: {
    loadDetalleAbono() {
      const body = {
        route: "/detalle_abonos_prestamo_desc",
        params: {
          id: this.$route.params.id,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) this.abono = response.data.data;
      });
    },
    close() {
      window.close();
    },
    print() {
      window.print();
    },
  },
  created() {
    this.loadDetalleAbono();
  },
  mounted() {
    const body = {
      permisos: JSON.stringify(["recepcion"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
};
</script>
<style scoped>
.pos-abono {
  font-family: Arial;
  max-width: 80mm;
  margin: 0 auto;
  background: white;
  line-height: normal !important;
  color: black !important;
}

.header {
  font-weight: bold;
}
.header p {
  font-size: 14px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

table {
  width: 100%;
  padding: 8px;
}
table thead th,
tfoot th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
table td,
th {
  font-size: 14px !important;
}

table th {
  text-align: left !important;
}
table td {
  text-align: right !important;
}
</style>
